.inline-error {
    font-size: medium;
}

.error-message {
    vertical-align: 4px;
}

@media screen and (min-width: 801px), screen and (min-height: 481px) {
    .error-message {
        vertical-align: 2.5px;
    }   
}