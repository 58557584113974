.browse-home-button {
    width: 77px;
    float: left;
    margin-left: -5px;
}
.browse-back-button {
    width: 77px;
    float: left;
    padding-left: 2px;
}
.browse-bounding-box {
    width: 77px;
    float: left;
    padding-left: 2px;
}
.add-button {
    float: right;
}
.search-button {
    float: right;
    margin-right: -4px;
}

.browse-data-section {
    padding-top: 20px;
}

.browse-ctrl-section {
    margin-left: -5px;
}

.browse-boundingbox-glyph {
    top: 8px;
}

.browse-box-title {
    padding-left: 15px;
}

.breadcrumb {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: -15px;
    width: 784px;
}

.breadcrumb-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}

.browse-title-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 784px;
    direction: rtl;
}

.browse-controls-container {
    margin-top: -11px;
}

.browse-table-container {
    padding-top: 20px;
}

@media screen and (min-width: 801px), screen and (min-height: 481px) {
    .browse-home-button {
        width: 77px;
        float: left;
        margin-left: -8px;
    }
    .browse-back-button {
        width: 77px;
        float: left;
        padding-left: 8px;
    }
    .browse-bounding-box {
        width: 77px;
        float: left;
        padding-left: 8px;
    }
    .search-button {
        float: right;
        margin-right: -8px;
    }

    .browse-ctrl-section {
        margin-left: -8px;
    }

    .browse-data-section {
        padding-top: 20px;
    }

    .browse-boundingbox-glyph {
        top: 9px;
    }

    .browse-box-title {
        padding-left: 15px;
    }

    .browse-bounding-box-content {
        margin-top: -11px !important;
    }

    .breadcrumb-ellipsis {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 1001px;
        direction: rtl;
        text-align: left;
        margin-bottom: -13px;
        font-size: 16px;
    }

    .browse-title-ellipsis {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 1001px;
        direction: rtl;
    }

    .browse-controls-container {
        margin-top: -12px;
    }

    .browse-table-container {
        padding-top: 20px;
    }
}
