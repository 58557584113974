.input-glyph {
    position: absolute;
    margin-top: 21px;
    margin-left: 16px;
}
.input-glyph.focus {
    color:#030f22
}
.xw-span-button {
    display: contents;
}
.xw-span-button.disabled button {
    opacity: 0.35;
    cursor: default!important;
}
.xw-span-button.disabled button:active{
    color: initial !important; 
    background: initial !important;
    text-shadow: none;
    box-shadow: none;
}
.input-glyph-padding {
    padding-left: 63px !important;
}
@media screen and (min-width: 801px), screen and (min-height: 481px) {
    .input-glyph {
        margin-left: 22px;
    }
    .input-glyph-padding {
        padding-left: 74px !important;
    }
}