textarea {
    font-family: 'Roboto Condensed','Noto Sans','Xerox Sans UI','Xerox Sans',Arial,sans-serif;
    resize: none;
}
#doc-name-vars {
    margin-top:-57px;
    width:185px;
}
.filename-variable-option {
    cursor: pointer;
}

.variable-block {
    padding: 4px 2px;
    border-radius: 5px;
    display: inline-block;
    margin:2px 0px;
    border: 2px solid #f7f7fb;
}

.variable-block.no-background {
    border-radius: 3px;
    background: none !important;
}




.xw-text.dynamic-input{
    position: absolute;
    visibility:hidden;
    z-index: -100;
    white-space: pre;
}



.ui-state-disabled.disabled-filename-option {
    opacity: 0.7
}

.ui-state-selected .ui-state-disabled.disabled-filename-option {
    color: #fff !important;
}

.xrx-keyboard .ui-keyboard-button span {
    font-weight:400 !important
}
@media screen and (min-width: 801px),
screen and (min-height: 481px) { 
}