.search-table-container{
    padding-top:3px;
  }
  .show-more-text {
    font-weight: normal;
}

@media screen and (min-width: 801px), screen and (min-height: 481px) {

  .search-table-container{
    padding-top:6px;
  }

}

.status-row-text {
  color: #323232 !important;
  opacity: 1;
}
table .status-row-text td {
  border-color: rgba(32,12,63,.35) !important
}
