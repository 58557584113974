
.instruction-text {
    font-size: 23px;
    clear:both;
    width:100%;
}

.document-name-reset-button {
    position: absolute;
    bottom: 0;
}