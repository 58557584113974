
.privacy-statement p {
  font-size: 16px;
  line-height: 150%;
  text-align: justify;
  margin-bottom: 10px;
  font-weight: lighter;
}

.privacy-statement h4 {
  margin-bottom: 20px;
}

.privacy-statement ul {
  margin-bottom: 20px;
}

.privacy-statement ul li {
  font-size: 16px;
  text-align: justify;
  font-weight: lighter;
}

.privacy-statement p.subtitle {
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: bold;
}

.footer-btn {
  position: absolute;
  bottom: 0;
  left:0;
  width: 100%;
}

.privacy-content-wrapper {
  text-align: center;
  display:block;
  float: left;
  height:326px;
  top: 60px;
  position:relative;
  margin-top:0px;
  }

.privacy-content {
  display:table-cell;
  vertical-align:middle;
  font-size:25px;
  height:242px;
  width:100%;
  padding-top:5px;
}

.privacy-content-buttons {
  position:absolute;
  width:390px;
  left: 50%;
  margin-left: -195px;
  top:266px;
}

@media (min-width: 801px) and (max-width: 1024px) {
  .privacy-content {
   font-size:28px;
  }
  .privacy-content-wrapper {
    height:460px;
    }
  
}
.privacy-content-container {
  word-wrap: normal;
  white-space: pre-wrap;       /* css-3 */
 white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
 white-space: -pre-wrap;      /* Opera 4-6 */
 white-space: -o-pre-wrap;    /* Opera 7 */
 word-wrap: break-word;       /* Internet Explorer 5.5+ */
 font-family: 'Roboto Condensed','Noto Sans','Xerox Sans UI','Xerox Sans',Arial,sans-serif;
}

.view-privacy-statement-btn {
  width: 100%;
  max-width: 790px;
}

@media screen and (min-width: 801px),
screen and (min-height: 481px) {
  .view-privacy-statement-btn {
    max-width: 1008px;
  }
  .privacy-content-buttons {
    width:488px;
    position:relative;
    left: 50%;
    margin-left: -244px;
    top:70px;
    padding-top:10px;
  }
  .privacy-content{
    height:400px;
  }
}