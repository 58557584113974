.reset-button .ui-button{
    margin-left:0px;
}
.sticky-footer {
    position: absolute;
    top:315px;
}
@media screen and (min-width: 801px),
screen and (min-height: 481px) { 
.sticky-footer{
    position:absolute;
    top:429px;
}
}