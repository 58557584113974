.base-modal-background {
    display: none;
    position: fixed;
    z-index: 600;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
.popup-blanket {
    background-color: rgb(43, 43, 43);
    background-color: rgba(43, 43, 43, 0.5);
}

.alert {
    background-color: rgb(27, 27, 27);
    background-color: rgba(27, 27, 27, 0.9);
}
.base-modal-alert.content-wrapper {
   display: table;
   height:100%;
   width: 100%;
}

.base-modal-alert.content {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}



.popup-content {
    background-color: #F7F7FB;
    margin: auto;
    height: 100%;
}

.action-bar-padding {
    padding-top: 78px;
}
.base-modal-background .xrx-button-text {
    text-shadow: none !important;
}