.float-left {
    float: left;
}
.float-right {
    float: right;
}
.float-left-no-margin {
    float: left;
    margin-left: -5px;
}
.float-right-no-margin {
    float: right;
    margin-right: -5px;
}
.cursor-pointer {
    cursor: pointer;
  }
.text-align-center {
    text-align: center;
}
.font-light {
    font-weight: 300 !important;
}
/* handle xerox button font-light */
.ui-button.font-light > span {
    font-weight: 300 !important;
}

.full-screen {
    margin-top: 77px;
    height: 403px;
}
.full-screen .no-header {
    height: 480px;
    padding-top: inherit;
}
.full-screen .borderRadius {
    border-radius: 0px !important;
}
.section {
    padding-left: 15px;
    padding-right: 15px;
}
.xm-1 {
    margin: 5px;
}
.xm-2 {
    margin:10px;
}
.xm-3 {
    margin:15px;
}
.xm-4 {
    margin:20px;
}
.xmt-1 {
    margin-top: 5px;
}
.xpt-1 {
    padding-top: 5px;
}
.xmb-1 {
    margin-bottom: 5px;
}
.xmt-2 {
    margin-top: 10px;
}
.xpt-2 {
    padding-top: 10px;
}
.xpt-3 {
    padding-top: 20px;
}
.xpb-1 {
    padding-bottom: 5px;
}

.xpb-2 {
    padding-bottom: 10px;
}
.xpl-2 {
    padding-left: 10px;
}
.xmb-2 {
    margin-bottom: 10px;
}
.xmt-3 {
    margin-top: 20px;
}
.xmb-3 {
    margin-bottom: 20px;
}
.xpb-3 {
    padding-bottom: 20px;
}
.xmx-1 {
    margin-left: 5px;
    margin-right: 5px;
}
.xmx-2 {
    margin-left: 10px;
    margin-right: 10px;
}
.xmx-3 {
    margin-left: 15px;
    margin-right: 15px;
}
.xmx-4 {
    margin-left: 20px;
    margin-right: 20px;
}
.xmy-1 {
    margin-top: 5px;
    margin-bottom: 5px;
}
.xmy-2 {
    margin-top: 10px;
    margin-bottom: 10px;
}
.xmy-3 {
    margin-top: 15px;
    margin-bottom: 15px;
}
.xmy-4 {
    margin-top: 20px;
    margin-bottom: 20px;
}
.xml-2 {
    margin-left: 10px;
}

.table-row-glyph {
    top: 7px;
}

.table-row-data {
    padding-left: 10px;
    position: relative;
    top: -2px;
}
.folder-title-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 400px;
    direction: ltr;
}

.show-more-text {
    font-size: 14px;
    font-weight: bold;
    padding-top:6px;
}

@media screen and (min-width: 801px),
screen and (min-height: 481px) { 
    .float-left-no-margin {
        margin-left: -8px;
    }
    .float-right-no-margin {
        margin-right: -8px;
    }
    .full-screen {
        margin-top:77px;
        height: 521px;
    }
    .full-screen .no-header {
        padding-top: inherit;
        height: 600px;
    }
    .section {
        padding-left: 32px;
        padding-right: 32px;
    }
    .xm-1 {
        margin: 8px;
    }
    .xm-2 {
        margin:16px;
    }
    .xm-3 {
        margin:24px;
    }
    .xm-4 {
        margin:32px;
    }
    .xmt-1 {
        margin-top: 8px;
    }
    .xpt-1 {
        padding-top: 8px;
    }
    .xpb-1 {
        padding-bottom: 8px;
    }
    .xmb-1 {
        margin-bottom: 8px;
    }
    .xmt-2 {
        margin-top: 16px;
    }
    .xmt-4 {
        margin-top: 32px;
    }
    .xmb-2 {
        margin-bottom: 16px;
    }
    .xpt-2 {
        padding-top: 16px;
    }
    .xpt-3 {
        padding-top: 32px;
    }
    .xpb-2 {
        padding-bottom: 16px;
    }
    .xpl-2 {
        padding-left: 16px;
    }
    .xmx-1 {
        margin-left: 8px;
        margin-right: 8px;
    }
    .xmx-2 {
        margin-left: 16px;
        margin-right: 16px;
    }
    .xmx-3 {
        margin-left: 24px;
        margin-right: 24px;
    }
    .xmx-4{
        margin-left: 32px;
        margin-right: 32px
    } 
    .xmy-1 {
        margin-top: 8px;
        margin-bottom: 8px;
    }
    .xmy-2 {
        margin-top: 16px;
        margin-bottom: 16px;
    }
    .xmy-3 {
        margin-top: 24px;
        margin-bottom: 24px;
    }
    .xmy-4{
        margin-top: 32px;
        margin-bottom: 32px
    }

    .table-row-glyph {
        top: 6px;
    }
    .table-row-data {
        padding-left: 15px;
    }
    .folder-title-ellipsis {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 500px;
        direction: ltr;
      }
}