.icon-column {
    width: 50px;
  }
  .scan-setting-icon {
      margin-top: -7px !important;
      height: 48px;
      width: 48px;
      border-radius: 4px;
    }
  
.scan-option-value {
  font-weight: 300;
}