.center-activity {
    margin: auto;
    padding-left: 2%;
    width: 17%;
}

@media screen and (min-width: 801px), screen and (min-height: 481px) {

    .center-activity {
        padding-left: 4%;
    }
}