
.text-small {
    font-size: 21px;
    line-height: 26px;
    letter-spacing: -0.4px;
}

@media screen and (min-width: 801px),
screen and (min-height: 481px) { 

.text-small {
    font-size: 17px;
    line-height: 24px;
    letter-spacing: -0.4px;
  }
}

.default-location-text {
  margin-left: 6px;
  margin-right:6px;
  clear:both;
}