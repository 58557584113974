body {
    overflow-y: hidden; /* Hide vertical scrollbar */
    overflow-x: hidden; /* Hide horizontal scrollbar */
  }
.display-block {
    display: block;
}
.text-white {
    color: white;
}
.purple {
    color: #200c3f;
}
.text-navy {
    color: #030F22;
}
.alert-body-text {
    color: #CCCCD2;
}
.text-display {
    opacity:50%;
}
.text-display.light {
    font-weight: 200 !important;
}

.ui-state-disabled  .text-display {
    opacity:100%;
}

.xrx-widget.xrx-segmentedcontrol-menu .ui-menu .ui-state-focus, .xrx-widget.xrx-segmentedcontrol-menu .ui-menu .ui-state-selected {
    font-weight: 400 !important;
}
.scan-option-icon, .scan-setting-icon {
 background: none !important;
 box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.16);
 padding: 0px !important;
}

#userButton-text {
    max-width: 90px;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-top: 8px;
}  

.single-line-bounding-box {
    height: 45px;
    padding-top: 6.5px !important;
    padding-bottom: 13.5px !important;
}
.single-line-bounding-box .xrx-bounding-box-content {
    white-space: nowrap;
    text-overflow: ellipsis;
}
.single-line-bounding-box .xrx-bounding-box-content .xrx-glyphicon {
    top: 8px;
    padding-right: 10px;
}

.original-size-popover .ui-menu-item {
    padding-left: 16px !important;
  }
  
.original-size-popover .xrx-glyphicon {
left: 128px !important;
}

.light-text-input {
    font-weight: 400 !important;
}

.single-line-ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.toggle-table-row-margin {
    margin-top: 3px;
}

.display-none {
    display: none;
}

.display-inline-block{
    display: inline-block;
}

.scroll-last-button-wrapper {
    min-height: 90px;
}

.app-version {
    opacity:50%;
    font-weight: 200;
    font-size:10px;
}

.enable-click {
    pointer-events: auto !important;
}
.xrx-action-bar-button-left-second {
    position: absolute !important;
    left:78px !important;
}

@media screen and (min-width: 801px),
screen and (min-height: 481px) { 
    #userButton-text {
        max-width: 180px;
    }

    .single-line-bounding-box {
        padding-top: 5px !important;
        padding-bottom: 10px !important;
    }
    .single-line-bounding-box .xrx-bounding-box-content .xrx-glyphicon {
        top: 8px;
        padding-right: 16px;
    }

    .original-size-popover .xrx-glyphicon {
        left: 112px !important;
    }

    .toggle-table-row-margin {
        margin-top: 1px;
    }
    .xrx-action-bar-button-left-second {
        position: absolute !important;
        left:84px !important;
    }
    .xrx-keyboard {
        box-shadow: none !important;
    }
}
