.filename-variable-option {
    cursor: pointer;
}

.variable-block {
    padding: 4px 2px;
    border-radius: 5px;
    background-color: rgba(32,12,63,.15);
}

.variable-block.no-background {
    padding: 4px 2px;
    border-radius: 3px;
    background: none !important;
}

.variable-block.selected {
    background-color: rgba(32,12,63,1);
    color: white;
}


.filename-input.variable-object-input input {
    border: none;
    padding: 0px;
    box-shadow: none;
}

.filename-input.variable-object-input input:focus {
    border: none;
    padding: 0px;
    box-shadow: none;
    background: 0 0;
}

.xw-text.dynamic-input{
    position: absolute;
    visibility:hidden;
    z-index: -100;
    white-space: pre;
}

#config-settings-variable {
    width:186px;
}



.filename-input.variable-object-input {
    color: #200c3f;
    box-shadow: #fff 0 1px 0 0 inset, #fff 1px 0 0 0 inset;
    border: 1px solid #200c3f;
    background: 0 0;
    border-radius: 3px;
    padding: 8px;
    margin: 5px;
    display: block;
    width: 575px;
}
.filename-input.variable-object-input.singular-text-input {
    padding: 16px;
    margin: 5px;
    width: 567px;
}

.filename-input.variable-object-input:focus-within {
    background-color: #fff;
    box-shadow: 0 0 0 1px #fff, inset 1px 1px 2px rgba(32,12,63,.5) !important;
}

.filename-input.variable-object-input input[type="text"] {
    margin: 0px;
}


.ui-state-disabled.disabled-filename-option {
    opacity: 0.7
}

.ui-state-selected .ui-state-disabled.disabled-filename-option {
    color: #fff !important;
}

@media screen and (min-width: 801px),
screen and (min-height: 481px) {
    .filename-input.variable-object-input {
        padding: 8px;
        margin: 8px;
        width: 788px;
        
    }
    .filename-input.variable-object-input.singular-text-input {
        padding: 16px;
        margin: 8px;
        width: 785px;
    }
}


.xrx-keyboard .ui-keyboard-button span {
    font-weight:400 !important
}

.xrx-text-input, input[type=text]{
    font-weight: 400 !important;
}