@font-face {
    font-family: "Roboto Condensed";
    font-weight: normal;
    src: local("Roboto Condensed"), url(../assets/fonts/RobotoCondensed-Regular.ttf) format("truetype");
}

@font-face {
    font-family: "Roboto Condensed";
    font-weight: 300;
    src: local("Roboto Condensed"), url(../assets/fonts/RobotoCondensed-Light.ttf) format("truetype");
}
.ui-button.xrx-widget {
    font-weight: 400 !important;
    font-size: 30px !important;
}

.xrx-background.navy {
    color: white;
}

.xrx-action-bar-label-text {
    position: relative;
    top: 22%;
    -webkit-transform: translateY(-22%);
    transform: translateY(-22%);
}

.scan-option-icon {
    height: 42px;
    width: 42px;
    margin-top: -5px;
    margin-left: -5px;
    margin-right: 10px;
    border-radius: 5px;
    background: white;
    padding: 2px;
    vertical-align: middle;    
  }

  .ui-xrx-popup-content {
    height: auto !important;
    }
@media screen and (min-width: 801px),
screen and (min-height: 481px) { 
    .xrx-action-bar-label {
        font-size: 36px;
    }
    .xrx-action-bar-label-container {
        font-size: 36px;
    }
    .ui-button.xrx-widget {
        font-weight: 400 !important;
        font-size: 28px !important;
    }    
    .xrx-popup {
        top: 0 !important;
        min-height: 600px;        
    }
}
.ui-xrx-alert-titlebar {
    display: none;
}

